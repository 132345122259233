import React from "react"

import { List, ListItem } from "@kiwicom/orbit-components"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Conserving Energy">
    <Hero
      title="Conserving Energy"
      subtitle="Top tips to save power if you are at risk of running low"
    />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Text spaceAfter="large">
            The tips below should generally only be used in case you are likely
            to run low on battery. If you have plenty of battery, prioritise
            running to schedule and maintaining passenger comfort.
          </Text>
          <List>
            <ListItem>
              Speed: Reduce your speed slightly as this uses less power. Going
              at 50mph on the motorway rather than 60mph will save a lot of
              energy but won't lose much time.
            </ListItem>
            <ListItem>
              Regen: Keep the regen on. This means the bus will recharge the
              batteries when you reduce the throttle. It also saves the brakes.
              You should be using regen all the time, not just when running low.
            </ListItem>
            <ListItem>
              Heating: Set the interior cabin heating to 21 degrees and the
              blowers to setting 2. Don't use the side floor heaters since they
              use a lot of power.
            </ListItem>
            <ListItem>
              Demisters: Use the demisters at setting 3 and only when required.
            </ListItem>
            <ListItem>
              Lights: At night only use the main interior lights in the first
              mode (all lights on when doors open, blue lights when driving).
            </ListItem>
          </List>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
